<template>
  <!-- 前台进度同步 弹窗 -->
  <v-dialog v-model="dialog.show" width="1250" max-width="100%">
    <v-card class="flex-column flex-justify-between" style="height:740px;">
      <v-card-title class="flex-0 flex-shrink-0 text-h5 lighten-2">前台属性同步</v-card-title>
      <v-divider />
      <v-card-text class="flex-1 px-0 overflow-auto">
        <div class="mb-3 max-width">
          <div class="flex-justify-between flex-y-center">
            <div class="flex-y-center ml-5">
              <v-btn
                color="primary"
                :loading="dialog.btnLoading"
                depressed
                width="100"
                height="30"
                class=" mr-2"
                @click="syncProjects('-1')"
              >全部同步</v-btn>
              <v-btn
                :disabled="!dialog.selected.length"
                color="primary"
                :loading="dialog.btnLoading"
                depressed
                width="100"
                height="30"
                @click="syncProjects"
              >批量同步</v-btn>
            </div>

            <!-- @input="searchDialogList" -->
            <div class="flex-justify-end px-3 py-2">
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="dialog.search.data.projectNumber"
                  class="mr-0"
                  placeholder="项目编号"
                  type="text"
                  allow-close
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="dialog.search.data.projectName"
                  class="mr-0"
                  placeholder="项目名称"
                  type="text"
                  allow-close
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-1" style="width:120px;">
                <!-- projectManagerName -->
                <form-item
                  v-model="dialog.search.data.projectManger"
                  class="mr-0"
                  placeholder="项目负责人"
                  type="text"
                  allow-close
                  @onEnter="initList()"
                />
              </div>
              <div class="flex-y-center mr-1" style="width:120px;">
                <form-item
                  v-model="dialog.search.data.syncFlag"
                  class="flex-1 flex-shrink-0"
                  :options="[{text:'是',value:'1'},{text:'否',value:'0'}]"
                  required
                  placeholder="是否已同步"
                  dense
                  type="select"
                  allow-close
                  @input="$forceUpdate()"
                  @select="(e)=>{dialog.search.data.syncFlag = e.val,initList()}"
                  @cleanVal="(e)=>{dialog.search.data.syncFlag = e.val,initList()}"
                />
              </div>
              <v-btn
                dark
                small
                color="primary"
                depressed
                min-width="34"
                height="30"
                style=" padding:0;"
                @click="initList()"
              >
                <v-icon size="18">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                dark
                small
                color="grey lighten-1"
                class="mx-1"
                title="重置筛选"
                depressed
                min-width="34"
                height="30"
                style="padding:0;"
                @click="dialog.search.data={},initList()"
              >
                <v-icon size="18">mdi-restore</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="flex-column flex-1 overflow-auto">
            <!-- dialog.showList.slice((dialog.search.pageSize*(dialog.search.pageNo-1)),(dialog.search.pageSize*dialog.search.pageNo)) -->
            <v-data-table
              :headers="dialog.headers"
              :items="dialog.list"
              :items-per-page="dialog.search.pageSize"
              class="pb-5 max-width header-grey"
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
              :loading="dialog.listLoading"
              disable-sort
              dense
            >
              <template v-slot:header.checkbox>
                <v-checkbox
                  v-model="dialog.selectedAll"
                  class="mt-0 py-2"
                  dense
                  label="全选"
                  hide-details
                  @change="(e)=>{selectedAll(e,'left')}"
                />
              </template>
              <template
                v-slot:item.rowIndex="{index}"
              >{{ (index+1)+(dialog.search.pageSize*(dialog.search.pageNo-1)) }}</template>
              <template v-slot:item.checkbox="{item}">
                <!-- item.syncFlag!=1 -->
                <v-checkbox
                  v-if="!['1','2'].includes(item.syncFlag)"
                  v-model="dialog.selected"
                  class="mt-0 pt-0"
                  dense
                  hide-details
                  :value="item.id"
                  @change="selectedIt()"
                />
              </template>
              <template v-slot:item.projectName="{item}">
                <div class=" font-overflow" style="max-width:400px;" :title="item.projectName">
                  {{ item.projectName }}
                </div>
              </template>
              <template
                v-slot:item.syncFlag="{item}"
              >
                <!-- syncFlag == 2，显示“同步中”，不允许勾选 -->
                {{ item.syncFlag==1?'是':(item.syncFlag==2?'同步中':(item.syncFlag==0?'否':'')) }}</template>
            </v-data-table>
            <div class="mt-2 pr-2 mb-5 max-width flex-justify-end">
              <!-- =>{dialog.search.pageSize=e,dialog.search.pageNo=1,$forceUpdate()} -->
              <pagination
                v-model="dialog.search.pageNo"
                :size="dialog.search.pageSize"
                :total="dialog.total"
                @input="loadList"
                @changePageSize="changePageSize"
              />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="flex-0 flex-shrink-0">
        <v-spacer />
        <v-btn depressed height="30" width="100" @click="dialog.show = false">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    apiList: {
      type: String,
      default: ''
    },
    saveApi: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: {
        show: false,
        data: {},
        search: {
          pageSize: 10,
          pageNo: 1,
          fieldCategory: 1,
          data: {}
        },
        total: 0,
        selectedAll: false,
        selected: [],
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '', value: 'checkbox', show: true, sortable: false },
          { text: '项目编号', value: 'projectNumber', show: true },
          { text: '项目名称', value: 'projectName', show: true },
          { text: '项目负责人', value: 'projectManagerName', show: true },
          { text: '里程碑', value: 'mileStone', show: true },
          { text: '是否已同步', value: 'syncFlag', show: true },
          { text: '最后同步时间', value: 'syncDate', show: true }
        ],
        list: [],
        showList: [],
        listLoading: false,
        btnLoading: false
      }
    }
  },
  methods: {
    show() {
      this.dialog.showList = []
      this.dialog.list = []
      this.dialog.show = true
      this.dialog.search.data = {}
      this.initList()
    },
    initList() {
      this.dialog.search.pageNo = 1
      this.loadList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.dialog.search.pageSize = e
      this.dialog.search.pageNo = 1
      this.$forceUpdate()
      this.loadList()
    },

    loadList() {
      // this.listLoading = true;
      this.dialog.listLoading = true
      const search = { ...this.dialog.search.data }
      search.pageSize = this.dialog.search.pageSize
      search.pageNo = this.dialog.search.pageNo
      // this.$api.getBonusProjectBonusTemplateList
      this.$http
        .get(this.apiList, { data: { ...search }})
        .then(res => {
          this.dialog.list = (res.result && res.result.records) || []
          this.dialog.total = (res.result && res.result.total) || 0
          this.dialog.selected = []
          this.dialog.selectedAll = false
          this.dialog.listLoading = false
        }).catch(() => {
          this.dialog.list = []
          this.dialog.total = 0
          this.dialog.selected = []
          this.dialog.selectedAll = false
          this.dialog.listLoading = false
        })
    },
    // 批量同步 属性
    syncProjects(type) {
      // text: '您确定将被选项目的奖金配置进行更新吗？更新后将无法还原同步前状态。'
      this.$confirm({
        text: '您确定将被选项目配置进行更新吗？更新后将无法还原同步前状态。'
      })
        .then(vm => {
          this.dialog.btnLoading = true
          const param = new URLSearchParams()
          param.append(
            'projectIds',
            type === '-1' ? '-1' : this.dialog.selected.join(',')
          )
          vm.loading = true
          // this.$api.bonusProjectBonusTemplateSync
          this.$http
            .post(this.saveApi, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.dialog.show = false
              this.dialog.btnLoading = false
              this.dialog.selected = []
              this.dialog.selectedAll = false
              this.$message.success(res.result || res.message)
              // this.getQueryProjectList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
              this.dialog.btnLoading = false
            })
        })
        .catch(() => {})
    },
    // 全选
    selectedAll(e) {
      if (e) {
        const _a = []
        this.dialog.list.forEach(_t => {
          // if (_t.syncFlag !== '1' && _a.indexOf(_t.id) === -1) {
          if (!['1', '2'].includes(_t.syncFlag) && _a.indexOf(_t.id) === -1) {
            _a.push(_t.id)
          }
        })
        // _a=this.dialog.showList.map(_t => { return _t.id})
        this.dialog.selected = _a
      } else {
        this.dialog.selected = []
      }
    },
    // 单选
    selectedIt() {}
  }
}
</script>

<style>
</style>
