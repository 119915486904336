<template>
  <v-layout max-width class="flex-column" style="min-height:100%;">
    <div class="flex-0 flex-shrink-0 flex-y-center flex-justify-between px-2 white" style>
      <!-- active=[trees[0].id] -->
      <v-tabs v-model="tab" @change="chooseId='',initStageList()">
        <v-tab :href="`#0`">默认阶段事件设置</v-tab>
        <v-tab :href="`#1`">奖金发放审阅人设置</v-tab>
      </v-tabs>
    </div>
    <!-- <div class=" flex-1"> -->

    <div class="flex-1 flex-align-start" style="margin-top:14px;">
      <v-tabs-items
        v-model="tab"
        class="flex-1"
        style="background:none;"
      >
        <v-tab-item class=" ml-2" :value="`0`">
          <v-row dense>
            <v-col :md="chooseId?5:12">
              <v-card class="elevation-0">
                <div class="flex-y-center pt-4 px-3 flex-wrap">
                  <v-btn
                    color="#00bea9"
                    depressed
                    dark
                    small
                    height="30"
                    style="padding:0 8px;"
                    min-width="30"
                    class="ml-2 mb-3"
                    @click="dialog.data={},dialog.show=true"
                  >
                    <v-icon size="16">mdi-plus</v-icon>新增阶段
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    dark
                    small
                    height="30"
                    style=" padding:0 8px;"
                    min-width="100"
                    class="ml-2 mb-3"
                    @click="showTongbu"
                  >
                    前台项目同步
                  </v-btn>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="list"
                  :items-per-page="search.pageSize"
                  class="flex-1 pb-5 max-width header-grey px-4"
                  hide-default-footer
                  item-key="id"
                  no-data-text="暂无查询数据"
                  :loading="listLoading"
                >
                  <!-- <template v-slot:item="{expand,item}"> -->
                  <template v-slot:item.rowIndex="{index}">
                    {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
                  </template>
                  <template v-slot:item.percent="{item}">
                    {{ item.percent + ' %' }}
                  </template>
                  <template v-slot:item.radio="{item}">
                    <v-radio-group
                      v-model="chooseId"
                      dense
                      height="18px"
                      class="ma-0 pa-0"
                      column
                      @change="chooseIt"
                    >
                      <v-radio :value="item.id" class="mt-5" />
                    </v-radio-group>
                  </template>
                  <template v-slot:item.action="{item}">
                    <div class=" flex-y-center">
                      <v-icon color="primary" size="18" class=" mr-1" title="编辑" @click="dialog.data={...item},dialog.show=true">mdi-square-edit-outline</v-icon>
                      <v-icon
                        color="error"
                        size="20"
                        class=" mr-1"
                        title="删除"
                        @click="activeList($api.bonusDeleteStage,'post','删除',{id:item.id})"
                      >mdi-delete-outline</v-icon>
                    </div>
                  </template>
                </v-data-table>
                <div class=" flex-justify-end">
                  <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getBonusStageList" @changePageSize="changePageSize" />
                </div>
              </v-card>
            </v-col>
            <v-col v-if="chooseId" md="7" class="">
              <v-card class="elevation-0 ml-1">
                <div class="flex-y-center pt-4 px-3 mb-3">
                  <v-btn
                    color="#00bea9"
                    depressed
                    dark
                    small
                    height="30"
                    style=" padding:0 8px;"
                    min-width="30"
                    class="ml-2"
                    @click="eventDialog.data={},eventDialog.show=true"
                  >
                    <v-icon size="18">mdi-plus</v-icon>新增事件
                  </v-btn>
                </div>
                <div class="flex-y-center">
                  <v-data-table
                    :headers="event.headers"
                    :items="event.list"
                    :items-per-page="event.search.pageSize"
                    class="flex-1 pb-5 max-width header-grey px-4"
                    hide-default-footer
                    item-key="id"
                    no-data-text="暂无查询数据"
                    :loading="event.listLoading"
                  >

                    <template v-slot:item.rowIndex="{index}">
                      {{ (index+1) }}
                    </template>
                    <template v-slot:item.percent="{item}">
                      {{ item.percent + ' %' }}
                    </template>
                    <template v-slot:item.action="{item}">
                      <div class=" flex-y-center">
                        <v-icon color="primary" size="18" class=" mr-1" title="编辑" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
                        <v-icon
                          color="error"
                          size="20"
                          class=" mr-1"
                          title="删除"
                          @click="activeList($api.bonusDeleteEvent,'post','删除',{id:item.id},'event')"
                        >mdi-delete-outline</v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class=" pt-12 ml-2 white" :value="`1`">
          <bonus ref="bonus" />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- 添加 编辑 阶段  start-->
    <v-dialog v-model="dialog.show" width="600">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.data.id?'编辑阶段':'添加阶段' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>阶段名称：
                </div>
                <form-item
                  v-model="dialog.data.stageName"
                  class="mr-0"
                  required
                  placeholder="阶段名称"
                  type="text"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>总奖金占比(%)：
                </div>
                <form-item
                  v-model="dialog.data.percent"
                  class=" flex-1 flex-shrink-0"
                  placeholder="总奖金占比(%)"
                  dense
                  required
                  type="text"
                  pattern="money"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <form-item
                  v-model="dialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  required
                  step="1"
                  :min="1"
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn width="80" height="30" class depressed @click="dialog.show = false">取消</v-btn>
            <v-btn width="80" height="30" type="submit" :loading="btnLoading" class="ml-3" color="primary" depressed>确定</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加 编辑 阶段  end-->

    <!-- 添加 编辑 事件  start-->
    <v-dialog v-model="eventDialog.show" width="700">
      <form v-if="eventDialog.show" class="max-height flex-column overflow-hidden" @submit.prevent="eventSubmitAdd">
        <v-card>
          <v-card-title class="font-weight-bold">{{ eventDialog.data.id?'编辑事件':'添加事件' }}</v-card-title>
          <v-divider />
          <v-card-text class="py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 pr-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>事件名称：
                </div>
                <form-item
                  v-model="eventDialog.data.eventName"
                  class="mr-0"
                  placeholder="事件名称"
                  required
                  type="text"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>事件占比（%）：
                </div>
                <form-item
                  v-model="eventDialog.data.percent"
                  class=" flex-1"
                  placeholder="事件占比（%）"
                  dense
                  type="text"
                  pattern="money"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2" style="width:170px;">
                  <span class="red--text ml-1">*</span>排序：
                </div>
                <form-item
                  v-model="eventDialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  step="1"
                  :min="1"
                  type="number"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn width="80" height="30" class="mr-2" depressed @click="eventDialog.show = false">取消</v-btn>
            <div style=" position:relative;">
              <input ref="form" style=" position:absolute;" type="submit" value="submit">
              <v-btn height="30" :loading="btnLoading" color="primary" width="80" depressed @click.stop="subSave">提交</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 添加 编辑 事件  end-->
    <sync ref="sync" :tab="tab" :api-list="$api.getBonusProjectBonusTemplateList" :save-api="$api.bonusProjectBonusTemplateSync" />
  </v-layout>
</template>

<script>
import sync from './model/sync.vue'
import bonus from './model/bonus'
export default {
  components: { sync, bonus },
  data() {
    return {
      tab: '0',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      chooseId: '', // 选中的阶段id
      headers: [ // 阶段表头
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '选择', value: 'radio', sortable: false },
        { text: '阶段名称', value: 'stageName' },
        { text: '百分比', value: 'percent' },
        { text: '操作', value: 'action', sortable: false }
      ],
      list: [], // 阶段数据
      btnLoading: false,
      listLoading: false,
      event: { // 事件列表
        search: {
          pageSize: 999,
          pageNo: 1,
          data: {}
        },
        total: 0,
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '事件名称', value: 'eventName' },
          { text: '百分比', value: 'percent' },
          { text: '操作', value: 'action', sortable: false }
        ],
        list: [],
        listLoading: false
      },
      dialog: { // 添加 编辑 阶段弹框
        show: false,
        type: '',
        data: {}
      },
      eventDialog: { // 事件 添加 编辑 弹框
        show: false,
        data: {}
      },

      progressEventTagList: [], // 节点标签列表
      roleList: [],
      taskPathId: [],
      isEditTaskPath: false,
      drawer: {
        show: false,
        data: {},
        task: { // 事件任务
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '任务名称', value: 'taskName', show: true },
            { text: '路径', value: 'uploadPath', show: true },
            // { text: "起止日期", value: "project_name", show: true },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        remind: { // 事件提醒
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '任务状态', value: 'remindStatus', show: true },
            { text: '提醒语句', value: 'remindTip', show: true },
            { text: '提醒角色', value: 'roleName', show: true },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        front: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '阶段事件名称', value: 'eventName' },
            { text: '状态', value: 'taskStatus' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        tag: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '阶段名称', value: 'name' },
            { text: '状态', value: 'birthday' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        },
        upload: {
          headers: [
            { text: '序号', value: 'rowIndex', width: '50', sortable: false },
            { text: '路径', value: 'folderPath' },
            { text: '归档通过文件个数', value: 'numberRange' },
            { text: '归档后事件自动完成', value: 'autoDone' },
            { text: '操作', value: 'active', sortable: false }
          ],
          list: []
        }
      },
      stageAndEvent: [],
      taskStatus: {
        '0': { text: '未开始', value: '0' },
        '1': { text: '进行中', value: '1' },
        '2': { text: '已完成', value: '2' }
        // {text:'未开始',value:'0'},
        // {text:'进行中',value:'1'},
        // {text:'已完成',value:'2'},
        // {text:'未开始',value:'1'},
        // {text:'进行中',value:'2'},
        // {text:'已完成',value:'4'},
        // {text:'未开始 + 进行中',value:'3'},
        // {text:'已完成',value:'4'},
        // {text:'未开始 + 已完成',value:'5'},
        // {text:'进行中 + 已完成',value:'6'},
        // {text:'未开始 + 进行中 + 已完成',value:'7'},
      },

      otherDialog: { // 事件里其他表格 编辑添加 弹窗
        show: false,
        type: '',
        remindRole: [],
        preEventId: [],
        taskStatus: [],
        data: {}
      },
      progressCategory: '',
      setDialog: { // 事件 添加 编辑 弹框
        show: false,
        createFlag: ''
      },
      trees: [],
      folderActive: []
    }
  },
  computed: {
  },
  watch: {
    taskPathId: {
      handler(val, old) {
        if (this.isEditTaskPath) {
          this.isEditTaskPath = false
          if (val && val.length) {
            let watchId = null
            let add = false
            if (val.length > old.length) {
              add = true
              watchId = val.find((t) => !old.includes(t))
            } else {
              watchId = old.find((t) => !val.includes(t))
            }
            const trees = this.$deepClone(this.trees)
            const pathId = []
            const pathName = []
            const getName = (arr, parentId) => {
              arr.forEach((_t, _i) => {
                if (!_t.isFild) {
                  if (parseInt(_t.id) === parseInt(parentId)) {
                    _t.isFild = true
                    if (pathId.indexOf(_t.id) === -1) {
                      pathId.push(_t.id)
                      pathName.unshift(_t.folderName)
                    }
                    if (_t.parentId !== 0) {
                      getName(trees, _t.parentId)
                    }
                  } else {
                    if (_t.children && _t.children.length) {
                      getName(_t.children, parentId)
                    }
                  }
                }
              })
            }
            getName(trees, watchId)
            if (add) {
              this.otherDialog.data.folderPath = this.otherDialog.data.folderPath ? (this.otherDialog.data.folderPath + ',' + pathName.join('/')) : pathName.join('/')
            } else {
              let pathArr = []
              if (this.otherDialog.data.folderPath) {
                pathArr = this.otherDialog.data.folderPath.split(',')
              }
              pathArr.splice(pathArr.indexOf(pathName.join('/')), 1)
              this.otherDialog.data.folderPath = pathArr.join(',')
              this.$forceUpdate()
            }
          } else {
            this.otherDialog.data.folderPath = ''
          }
          this.$forceUpdate()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getBonusStageList()
  },
  methods: {
    initStageList() {
      this.total = 0
      this.search.pageNo = 1
      this.getBonusStageList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getBonusStageList()
    },
    // 获取进度 阶段
    getBonusStageList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.post(this.$api.getBonusStageList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = (res.result && res.result.total) || 0
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 获取事件列表
    getBonusEventList() {
      this.event.listLoading = true
      const search = {}
      search.pageSize = this.event.search.pageSize
      search.pageNo = this.event.search.pageNo
      search.stageId = this.chooseId
      this.$http.post(this.$api.getBonusEventList, { data: search, type: 'application/x-www-form-urlencoded' }).then(res => {
        this.event.list = (res.result && res.result.records) || []
        this.event.total = (res.result && res.result.total) || 0
        this.event.listLoading = false
      }).catch(() => {
        this.event.listLoading = false
      })
    },
    // 选择阶段
    chooseIt(e) {
      this.getBonusEventList()
    },
    // 确认 删除阶段  禁用 恢复 阶段
    activeList(api, method, title, params, event) {
      this.$confirm({ text: '是否确认' + title + '？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in params) {
          param.append(i, params[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          if (event === 'event') {
            this.getBonusEventList()
          } else {
            if (params.id === this.chooseId) this.chooseId = ''
            this.initStageList()
          }
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 点击提交
    subSave() {
      const u = navigator.userAgent
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.focus()
      this.$nextTick(() => {
        document.body.removeChild(input) // 获取焦点移除节点
        if (u.indexOf('Firefox') > -1) {
          setTimeout(() => {
            this.$refs.form.click()
          }, 300)
        } else {
          this.$refs.form.click()
        }
      })
    },
    // 阶段确认 添加 编辑
    submitAdd() {
      let url = this.$api.bonusAddStage
      const method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.bonusEditStage
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        this.getBonusStageList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 事件 编辑弹窗
    showEdit(item) {
      this.eventDialog.show = true
      this.eventDialog.data = { ...item }
    },
    // 确认 添加编辑事件
    eventSubmitAdd() {
      let url = this.$api.bonusAddEvent
      const method = 'post'
      if (this.eventDialog.data.id) {
        url = this.$api.bonusEditEvent
      }
      const _data = { ...this.eventDialog.data }
      _data.stageId = this.chooseId
      this.btnLoading = true
      this.$http[method](url, { data: { ..._data }}).then(res => {
        this.$message.success(res.message)
        this.eventDialog.show = false
        this.eventDialog.data = {}
        this.btnLoading = false
        this.getBonusEventList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    showTongbu() {
      this.$refs.sync && this.$refs.sync.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title {
  width: 150px;
  color: #777;
}
</style>
