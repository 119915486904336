<template>
  <div>
    <div class=" flex-y-center mt-2 px-4 pb-8">
      <span class=" flex-0 flex-shrink-0 flex-justify-end" style="width:140px;">奖金发放审阅人：</span>
      <form-item
        v-model="currentName"
        class=" flex-0 flex-shrink-0"
        style="width: 250px"
        dense
        disabled
        type="text"
        @input="$forceUpdate()"
      />
      <a class=" ml-2" @click="showUserDialog">选择审阅人</a>
    </div>
    <div class=" flex-y-center px-4 pb-12 mb-12" style="margin-left:140px;">
      <!-- <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="saveBonusLimit">保存</v-btn> -->
      <v-btn small color="primary" depressed min-width="120" class="mr-2" height="30" style="padding:0 8px" @click="$refs.syncDialog.show()">同步</v-btn>
    </div>
    <v-dialog v-model="userDialog.show" width="700" :content-class="userDialog.show?'flex-column overflow-hidden':''">
      <form class=" flex-1 max-height flex-column overflow-hidden" @submit.prevent="sureUser">
        <v-card class=" flex-1 flex-column overflow-hidden">
          <v-card-title class="flex-0 flex-justify-between flex-y-center">
            <span class="text-h6">设置审阅人</span>
            <v-icon @click="userDialog.show=false,userDialog.userIds=[]">mdi-close</v-icon>
          </v-card-title>
          <!-- <v-card-text class="flex-1 pb-5 px-0">
            <div class="flex-1 flex-y-center flex-wrap overflow-auto mt-6 px-12">
              <v-checkbox v-for="(item,index) in memberList" :key="index" v-model="userDialog.data.userIds" style="width:120px;" :value="item.userId" :label="item.realname" />
            </div>
          </v-card-text> -->
          <v-card-text class="flex-1 flex-column pb-5 px-0 overflow-hidden">
            <span class="px-6 pt-3">（选择人员后，可拖拽排序）</span>
            <div class="flex-1 flex-column flex-justify-between overflow-hidden">
              <div class=" flex-0 flex-shrink-0 overflow-auto" style="max-height:130px;">
                <draggable v-model="userDialog.userIds" element="div" class="px-6 pt-3" style="min-height:100px;">
                  <template v-for="(item,index) in userName">
                    <v-chip
                      :key="index"
                      class="ma-1"
                      style="cursor: move;"
                      filter
                      title="拖拽排序"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </draggable>
              </div>
              <v-divider />
              <div class=" flex-1 flex-y-center flex-wrap px-6 overflow-auto">
                <template v-if="memberList.length">
                  <v-checkbox v-for="(item,index) in memberList" :key="index" v-model="userDialog.userIds" class="mr-2" style="width:120px;" :hide-details="true" :value="item.id" :label="item.realname" />
                </template>
                <div v-else class="mt-3">
                  暂无可选用户……
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class=" flex-0">
            <v-spacer />
            <v-btn width="80" :loading="btnLoading" class="mr-2" depressed @click="userDialog.show = false">取消</v-btn>
            <v-btn width="80" color="primary" type="submit" :loading="btnLoading" class depressed>确认</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <syncDialog ref="syncDialog" :api-list="$api.projectBonusExamineList" :save-api="$api.projectBonusExamineSync" />
  </div>
</template>

<script>
import syncDialog from './sync'
export default {
  components: { syncDialog },
  data() {
    return {
      currentUser: [],
      currentName: [],
      userDialog: {
        currentUser: [], // 当前审阅人
        show: false,
        data: {},
        signatureUsers: [],
        userIds: []
      },
      memberList: [],
      btnLoading: false
    }
  },
  computed: {
    userName() {
      const _name = []
      this.userDialog.userIds.forEach((item, index) => {
        if (this.memberList.filter(_t => _t.id === item) && this.memberList.filter(_t => _t.id === item).length) {
          _name.push(this.memberList.filter(_t => _t.id === item)[0].realname)
        }
      })
      return _name
    }
  },
  mounted() {
    this.getTaskDispatchOption()
    this.getUser()
  },
  methods: {
    // 获取当前审阅人
    getUser() {
      // this.currentUser
      this.$http.get(this.$api.getBonusExamineUsers, { data: {}, type: 'application/x-www-form-urlencoded' }).then(res => {
        const currentUser = res.result || []
        const names = currentUser.map(t => {
          return t.examineUserName
        })
        this.currentName = names || []
        const ids = currentUser.map(t => {
          return t.examineUserId
        })
        this.currentUser = ids || []
      }).catch(() => {
        this.currentName = []
        this.currentUser = []
      })
    },
    showUserDialog(e) {
      this.userDialog.userIds = this.currentUser
      this.userDialog.show = true
    },
    // 获取审阅人
    getTaskDispatchOption(id) {
      const _data = {}
      _data.userCategory = '1'
      _data.pageSize = 9999
      _data.pageNo = 1
      const param = new URLSearchParams()
      for (const i in _data) {
        if (_data[i]) param.append(i, _data[i])
      }
      this.$http.get(this.$api.userList, { data: param }).then(res => {
        const memberList = (res.result && res.result.records) || []
        const _List = []
        memberList.forEach(item => {
          if (!(item.expirationTime && (new Date().getTime() > (new Date(item.expirationTime).getTime() + 86400000)))) {
            _List.push(item)
          }
        })
        this.memberList = _List
      }).catch(() => {
        this.memberList = []
      })
    },
    // 确认提交 审阅人
    sureUser() {
      const userIds = this.userDialog.userIds
      this.btnLoading = true
      this.$http.post(this.$api.saveBonusExamineUsers, { data: { examineUsers: userIds }, type: '' }).then(res => {
        this.btnLoading = false
        this.$message.success(res.result)
        this.getUser()
        // const nameArrs = this.memberList.filter(t => this.userDialog.userIds.indexOf(t.id) !== -1) || []
        // const names = nameArrs.map(t => {
        //   return t.realname
        // })
        // this.currentName = names || []
        // this.$emit('ok')
        this.userDialog.show = false
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 保存奖金发放流程
    saveBonusLimit() {
      // this.currentName
      // this.btnLoading = true
      // this.$http.put(this.$api.saveBonusExamineUsers, { data: { projectBonusExamineTemplateList: this.userDialog.taskId, userIds: userIds }, type: 'application/x-www-form-urlencoded' }).then(res => {
      //   this.btnLoading = false
      //   this.$emit('ok')
      //   this.userDialog.show = false
      // }).catch(() => {
      //   this.btnLoading = false
      // })
    }
  }
}
</script>

<style>

</style>
